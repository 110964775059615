import { DateTime } from 'luxon'
import React from 'react'
import Card from '../../../../components/atoms/card/Card'
import Text from '../../../../components/atoms/text/Text'
import { terms } from '../../../../config'
import { useHolidays } from '../../../../modules/seats/hooks'
import styles from './Timetable.module.css'

const Timetable = () => {
  const { data: holidays } = useHolidays()

  return (
    <>
      <section className={styles.container}>
        <Text weight="500" size="xl" align="center" className={styles.title}>
          Horario del coworking
        </Text>

        <div className={styles.timetables}>
          <Card className={styles.timeDetails}>
            <div className={styles.header}>
              <Text weight="500" size="l" align="center" className={styles.title}>
                Lunes a Viernes
              </Text>
              <Text weight="500" size="m" align="center" className={styles.title}>
                (No festivos)
              </Text>
            </div>
            <div className={styles.row}>
              <Text size="sm">De 8:00 a 21:00</Text>
            </div>
            <div className={styles.row}>
              <Text size="sm">Joranada de mañana: 8:00 - 14:30</Text>
            </div>
            <div className={styles.row}>
              <Text size="sm">Joranada de tarde: 14:30 - 21:00</Text>
            </div>
          </Card>

          <Card className={styles.timeDetails}>
            <div className={styles.header}>
              <Text weight="500" size="l" align="center" className={styles.title}>
                {`Cierre en Festivos ${holidays[0] ? holidays[0].getFullYear() : new Date().getFullYear()}`}
              </Text>
              <Text weight="500" size="m" align="center" className={styles.title}>
                (Nacionales)
              </Text>
            </div>
            {holidays.map((festivo, index) => (
              <div key={index} className={styles.row}>
                <Text size="sm">{DateTime.fromJSDate(festivo).toFormat("dd 'de' MMMM")}</Text>
              </div>
            ))}
          </Card>

          <Card className={styles.timeDetails}>
            <div className={styles.header}>
              <Text weight="500" size="l" align="center" className={styles.title}>
                Agosto
              </Text>
              <Text weight="500" size="m" align="center" className={styles.title}>
                (No festivos)
              </Text>
            </div>
            <div className={styles.row}>
              <Text size="sm">El Coworking abre sus puertas con normalidad</Text>
            </div>
          </Card>
        </div>

        <div>
          <a className={styles.button} href={terms} rel="noreferrer" target="_blank">
            Consulta la normativa
          </a>
        </div>
      </section>
    </>
  )
}

export default React.memo(Timetable)
